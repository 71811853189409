// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-jsx": () => import("./../../../src/pages/announcements.jsx" /* webpackChunkName: "component---src-pages-announcements-jsx" */),
  "component---src-pages-annual-general-meetings-jsx": () => import("./../../../src/pages/annual-general-meetings.jsx" /* webpackChunkName: "component---src-pages-annual-general-meetings-jsx" */),
  "component---src-pages-corporate-governance-jsx": () => import("./../../../src/pages/corporate-governance.jsx" /* webpackChunkName: "component---src-pages-corporate-governance-jsx" */),
  "component---src-pages-financial-results-jsx": () => import("./../../../src/pages/financial-results.jsx" /* webpackChunkName: "component---src-pages-financial-results-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrated-reports-jsx": () => import("./../../../src/pages/integrated-reports.jsx" /* webpackChunkName: "component---src-pages-integrated-reports-jsx" */),
  "component---src-pages-presentations-jsx": () => import("./../../../src/pages/presentations.jsx" /* webpackChunkName: "component---src-pages-presentations-jsx" */),
  "component---src-pages-sens-jsx": () => import("./../../../src/pages/sens.jsx" /* webpackChunkName: "component---src-pages-sens-jsx" */)
}

